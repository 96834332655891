<div class="centered">
	<div class="empty-space-32"></div>
	<h1> Kontakt</h1>
	<h2>Öffnungszeiten</h2>
	<ul>
		<li>Montags: 08-18 Uhr</li>
		<li>Dienstags: 08-18 Uhr</li>
		<li>Mittwochs: 08-18 Uhr</li>
		<li>Donnerstags: 08-18 Uhr</li>
		<li>Freitags: 08-17 Uhr</li>
	</ul>
	<p>Absagen oder Terminänderungen müssen <span style="text-decoration: underline">24h vor dem Termin</span>
		mitgeteilt werden, sonst werden Stornierungkosten in
		Höhe von 50% fällig.
	</p>
	<h2 style="text-align: center">Wie erreichen Sie mich?</h2>
	<div class="contact-space vertical-box centered">
		<a href="https://wa.me/{{emi.phoneInternationalSimple}}">
			<button mat-raised-button color="primary">schreibe mir eine WhatsApp Nachricht</button>
		</a>
		<a href="tel:{{irina.phoneNational}}">
			<button mat-raised-button color="primary">ruf mich an: {{irina.phoneNational}}</button>
		</a>
		<a href="tel:{{emi.phoneInternational}}">
			<button mat-raised-button color="primary">ruf mich an für mobile
				Fußpflege: {{emi.phoneInternational}}</button>
		</a>
		<a href="mailto:{{address.mail}}?subject=Kontakt%20Anfrage">
			<button mat-raised-button color="primary">schreibe mir eine Email: {{address.mail}}</button>
		</a>
	</div>
	<div class="contact-space centered vertical-box width-100">
		<a href="https://goo.gl/maps/ppRF8dVRXbFUQXSSA" target="_blank">
			<div class="contact-space"> Sie finden mich hier: <br>
				{{address.street}}<br>
				{{address.plz}} {{address.city}}
			</div>
		</a>
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2516.8842103736356!2d7.656124615747321!3d50.888853379538155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bebb953936d49d%3A0x6273ce67383eb8e1!2sStudio-Lissa!5e0!3m2!1sde!2sde!4v1598463472208!5m2!1sde!2sde"
			style="border:0;" allowfullscreen="" aria-hidden="false"
			tabindex="0"></iframe>
	</div>
</div>
