import {Component, Input} from '@angular/core';
import {offerPage, offerFootCare, offerMassage, offerManikuere, offerKosmetik, aboutMe,mainPage} from '../_shared/const';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
	offerPage = offerPage
	offerFootCare = offerFootCare
	offerMassage = offerMassage
	offerManikuere = offerManikuere
	offerKosmetik = offerKosmetik
	aboutMe = aboutMe
	mainPage = mainPage
	@Input() mobileContent: boolean

}
