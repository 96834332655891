import {Component, OnInit} from '@angular/core';
import {ContactService} from '../_services/contact.service';
import {Contact} from '../_entities/contact';
import {Address} from '../_entities/adress';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	irina: Contact;
	emi: Contact;
	address: Address;

	constructor(
		private contactService: ContactService
	) {
	}

	ngOnInit(): void {
		this.contactService.getContactIrina().subscribe(contact => this.irina = contact)
		this.contactService.getContactEmi().subscribe(contact => this.emi = contact)
		this.contactService.getAddress().subscribe(contact => this.address = contact)
	}

}
