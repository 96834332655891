import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {ContactComponent} from './contact/contact.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {offerPage} from './_shared/const';
import {PageOfferComponent} from './page-offer/page-offer.component';
const routes: Routes = [
	{
		path: '',
		component: MainPageComponent
	},
	{
		path: 'kontakt',
		component: ContactComponent
	},
	{
		path: offerPage + '/:path',
		component: PageOfferComponent
	},
	{
		path: 'impressum',
		component: ImpressumComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
