import {Component} from '@angular/core';
import {Card} from '../_entities/card';
import {OfferService} from '../_services/offer.service';
import {NavigationEnd, Router} from '@angular/router';
import {Pretext} from '../_entities/pretext';

@Component({
	selector: 'app-page-offer',
	templateUrl: './page-offer.component.html',
	styleUrls: ['./page-offer.component.scss']
})
export class PageOfferComponent {

	cards: Card[];
	url: string;
	private menuString: string;
	pretext: Pretext;

	constructor(
		private offerService: OfferService,
		private router: Router,
	) {
		this.router.events.subscribe(value => {
			if (value instanceof NavigationEnd) {
				this.url = decodeURIComponent(this.router.url);
				const urlSplit = this.url.split('/');
				this.menuString = urlSplit[2];
				this.getCards();
				this.getPreText()
			}
		});
	}

	getCards() {
		this.offerService.getItemsForOffer(this.menuString).subscribe(cards => {
			this.cards = cards
		})
	}

	getPreText() {
		this.offerService.getPreText(this.menuString).subscribe(pretext => {
			this.pretext = pretext
		})
	}
}
