import {Component, Input} from '@angular/core';
import {Card} from '../../_entities/card';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent {
	@Input() card: Card
	displayedColumns: string[] = ['name', 'price'];
}
