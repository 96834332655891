import {Component} from '@angular/core';
import {offerPage, offerFootCare, offerMassage, offerKosmetik, offerManikuere} from '../_shared/const';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
	offerPage = offerPage
	offerFootCare = offerFootCare
	offerMassage = offerMassage
	offerKosmetik = offerKosmetik
	offerManikuere = offerManikuere
}
