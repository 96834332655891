import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Card} from '../_entities/card';
import {
	aboutMe, offerAboutMePretext,
	offerFootCare,
	offerFootCareCards,
	offerFootCarePretext,
	offerKosmetik,
	offerKosmetikCards,
	offerKosmetikPretext,
	offerManikuere,
	offerManikureCards,
	offerManikurePretext,
	offerMassage,
	offerMassageCards,
	offerMassagePretext,
} from '../_shared/const';
import {Pretext} from '../_entities/pretext';

@Injectable({
	providedIn: 'root'
})
export class OfferService {

	constructor() {
	}

	getItemsForOffer(offerString: string): Observable<Card[]> {
		if (offerString === offerFootCare) {
			return of(offerFootCareCards)
		} else if (offerString === offerKosmetik) {
			return of(offerKosmetikCards)
		} else if (offerString === offerManikuere) {
			return of(offerManikureCards)
		} else if (offerString === offerMassage) {
			return of(offerMassageCards)
		} else {
			return of([])
		}
	}

	getPreText(offerString: string): Observable<Pretext> {
		if (offerString === offerFootCare) {
			return of(offerFootCarePretext)
		} else if (offerString === offerKosmetik) {
			return of(offerKosmetikPretext)
		} else if (offerString === offerManikuere) {
			return of(offerManikurePretext)
		} else if (offerString === offerMassage) {
			return of(offerMassagePretext)
		} else if (offerString === aboutMe) {
			return of(offerAboutMePretext)
		} else {
			return of({})
		}
	}

}
